import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';




const fetchCsrfToken = async () => {
    await axios.get('/api/getcsrf/');
    return Cookies.get('csrftoken');
};

const UserList = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            const csrfToken = await fetchCsrfToken();
            axios.get('/api/users/', {
                headers: {
                    'X-CSRFToken': csrfToken
                },
                withCredentials: true
            })
                .then(response => {
                    setUsers(response.data);
                })
                .catch(error => {
                    console.error('There was an error fetching the users!', error);
                });
        };

        getUsers();
    }, []);

    return (
        <div className='Utenti bg-white border-black container mx-auto flex flex-col items-center w-[90%] sm:w-[75%] md:w-[65%] lg:w-[60%] min-w-[360px] justify-center mt-5 rounded-b-xl'>
            <h1 className='text-2xl font-bold mb-4'>User List</h1>
            <ul className='text-lg'>
                {users.map(user => (
                    <li key={user.id} className='mb-2'>{user.username} - {user.email} - {user.first_name} {user.last_name} - {user.role}</li>
                ))}
            </ul>
        </div>
    );
};

export default UserList;