import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function GoogleAuthRedirect() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Extract the code or token from URL parameters
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');
        // Optionally, you might have tokens directly in the URL fragment

        console.log('GoogleAuthRedirect: ', code);

        if (code) {
            // Exchange the code for tokens here, or directly authenticate the user
            axios.post('/api/auth/google', { code })
                .then(response => {
                    console.log('Authentication successful', response);
                    // Redirect the user to another part of your application
                    navigate('/dashboard');
                })
                .catch(error => {
                    console.error('Authentication failed', error);
                    // Handle errors or redirect the user to an error page
                    navigate('/login');
                });
        } else {
            // No code in URL, redirect back to login or handle accordingly
            navigate('/login');
        }
    }, [location, navigate]);

    return (
        <div>
            Processing Google Authentication...
        </div>
    );
}

export default GoogleAuthRedirect;