// import React, { useContext, useEffect, useState } from 'react';
// import Template from './Template';
// import './CartelleCliniche.css';
// // CONTEXTS
// import FormInputContext from '../../Contexts/FormInputContext';
// import { Tab, TabContainer, TabBar } from '../Element/TabContainer';


// function CartelleCliniche() {
//   const [activeTab, setActiveTab] = useState(0);


//   return (
//     <Template>
//       <TabBar activeTab={activeTab} setActiveTab={setActiveTab}>
//         <Tab> CTC 1 </Tab>
//         <Tab> CTC 2 </Tab>
//       </TabBar>
//       <TabContainer activeTab={activeTab}>
//         <div className="CTC 1">
//           <div className="Ospiti">
//             <table className="table table-hover">
//               <thead>
//                 <tr className='table-secondary'>
//                 <th scope="col">Ospite</th>
//                 <th scope="col">Psichiatra</th>
//                 <th scope="col">U.O.B.</th>
//                 <th scope="col">Operatore.d.a.</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr className="table-active">
//                 <th scope="row">Mario Rossi</th>
//                 <td>Dr House</td>
//                 <td>1</td>
//                 <td>Fido</td>
//                 </tr>
//                 <tr className="table-active">
//                 <th scope="row">Fabio Fabi</th>
//                 <td>Dr Strangelove</td>
//                 <td>1</td>
//                 <td>Fida</td>
//                 </tr>
//                 <tr className="table-active">
//                 <th scope="row">Marco Bianchi</th>
//                 <td>Dr Strange</td>
//                 <td>1</td>
//                 <td>Fede</td>
//                 </tr>
//                 <tr className="table-active">
//                 <th scope="row">Salvatore Esposito</th>
//                 <td>Dr Acula</td>
//                 <td>2</td>
//                 <td>Link</td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>
//         <div className='CTC2'>
//         </div>
//         {/* <div className='TabAccesso'>
//           <form>
//             <div className="form-floating mb-3">
//               <input type="username" className="form-control" id="floatingInput" placeholder="MRossi" ></input>
//               <label for="floatingInput">Username</label>
//             </div>
//             <div className="form-floating PasswordBlock">
//               <input type="password" className="form-control" id="floatingPassword" placeholder="Password" autocomplete="off"></input>
//               <label for="floatingPassword">Password</label>
//               <button type="submit" className="btn btn-dark btn-invio">Invio</button>
//             </div>
//           </form>	
//         </div> */}
//         {/* <div>

//         </div> */}
//       </TabContainer>
//     </Template>
//     );
// }

// export default CartelleCliniche;
// import {
//   Combobox,
//   ComboboxButton,
//   ComboboxInput,
//   ComboboxOption,
//   ComboboxOptions,
// } from '@headlessui/react';
// import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
// import clsx from 'clsx';
// import { useState } from 'react';

// const people = [
//   { id: 1, name: 'Tom Cook' },
//   { id: 2, name: 'Wade Cooper' },
//   { id: 3, name: 'Tanya Fox' },
//   { id: 4, name: 'Arlene Mccoy' },
//   { id: 5, name: 'Devon Webb' },
// ];

// const programs = [
//   { id: 1, name: 'Programma 1' },
//   { id: 2, name: 'Programma 2' },
//   { id: 3, name: 'Programma 3' },
//   { id: 4, name: 'Programma 4' },
//   { id: 5, name: 'Programma 5' },
// ];

// export default function Example() {
//   const [personQuery, setPersonQuery] = useState('');
//   const [programQuery, setProgramQuery] = useState('');
//   const [selectedPerson, setSelectedPerson] = useState(people[0]);
//   const [selectedProgram, setSelectedProgram] = useState(programs[0]);

//   const filteredPeople =
//     personQuery === ''
//       ? people
//       : people.filter((person) =>
//           person.name.toLowerCase().includes(personQuery.toLowerCase())
//         );

//   const filteredPrograms =
//     programQuery === ''
//       ? programs
//       : programs.filter((program) =>
//           program.name.toLowerCase().includes(programQuery.toLowerCase())
//         );

//   return (
//     <div className="mx-auto h-screen pt-0">
//       <div className="flex flex-row justify-center gap-x-8 mx-2 ">
//         {/* Program Selector */}
//         <Combobox value={selectedProgram} onChange={setSelectedProgram}>
//           <div className="relative w-64">
//             <span className="block mb-1 text-black">Programma</span>
//             <ComboboxInput
//               className={clsx(
//                 'w-full rounded-lg border-none bg-black/20 py-1.5 pr-8 pl-3 text-sm text-white',
//                 'focus:outline-none focus:ring-2 focus:ring-white/25'
//               )}
//               displayValue={(program) => program?.name}
//               onChange={(event) => setProgramQuery(event.target.value)}
//             />
//             <ComboboxButton className="absolute inset-y-9 right-0 px-2.5">
//               <ChevronDownIcon className="w-5 h-5 text-white/60" />
//             </ComboboxButton>
//             <ComboboxOptions
//               className={clsx(
//                 'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-black/80 text-white shadow-lg',
//                 'transition duration-150 ease-out ring-1 ring-black/5 focus:outline-none'
//               )}
//             >
//               {filteredPrograms.map((program) => (
//                 <ComboboxOption
//                   key={program.id}
//                   value={program}
//                   className={({ active }) =>
//                     clsx(
//                       'cursor-default select-none relative py-2 pl-3 pr-9',
//                       active ? 'bg-white/10' : ''
//                     )
//                   }
//                 >
//                   {({ selected }) => (
//                     <>
//                       <span
//                         className={clsx('block truncate', selected && 'font-semibold')}
//                       >
//                         {program.name}
//                       </span>
//                       {selected && (
//                         <span className="absolute inset-y-0 right-0 flex items-center pr-4">
//                           <CheckIcon className="w-5 h-5 text-white" />
//                         </span>
//                       )}
//                     </>
//                   )}
//                 </ComboboxOption>
//               ))}
//             </ComboboxOptions>
//           </div>
//         </Combobox>

//         {/* People Selector */}
//         <Combobox
//           value={selectedPerson} // Bind the current selection
//           onChange={(value) => {
//             setSelectedPerson(value); // Update selection
//             setQuery(''); // Clear the query after selection
//           }}
//         >
//           <div className="relative w-64">
//             {/* Label */}
//             <span className="block mb-1 text-black">Utente</span>
//             {/* Input */}
//             <ComboboxInput
//               className="w-full rounded-lg border-none bg-black/5 py-1.5 pr-8 pl-3 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white/25"
//               displayValue={(person) => person?.name} // Display selected person's name
//               onChange={(event) => setQuery(event.target.value)} // Update query on typing
//             />
//             {/* Dropdown Button */}
//             <ComboboxButton className="absolute inset-y-0 right-0 px-2.5">
//               <ChevronDownIcon className="w-5 h-5 text-white/60" />
//             </ComboboxButton>
//             {/* Options */}
//             <ComboboxOptions
//               className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-black/80 text-white shadow-lg ring-1 ring-black/5 focus:outline-none"
//             >
//               {filteredPeople.map((person) => (
//                 <ComboboxOption
//                   key={person.id}
//                   value={person} // The value passed to `onChange`
//                   className={({ active }) =>
//                     clsx(
//                       'cursor-default select-none relative py-2 pl-3 pr-9',
//                       active ? 'bg-white/10' : ''
//                     )
//                   }
//                 >
//                   {({ selected }) => (
//                     <>
//                       <span
//                         className={clsx(
//                           'block truncate',
//                           selected && 'font-semibold'
//                         )}
//                       >
//                         {person.name}
//                       </span>
//                       {selected && (
//                         <span className="absolute inset-y-0 right-0 flex items-center pr-4">
//                           <CheckIcon className="w-5 h-5 text-white" />
//                         </span>
//                       )}
//                     </>
//                   )}
//                 </ComboboxOption>
//               ))}
//             </ComboboxOptions>
//           </div>
//         </Combobox>

//       </div>
//     </div>
//   );
// }

import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { PaperClipIcon } from '@heroicons/react/20/solid'

import clsx from 'clsx'
import { useState } from 'react'

const people = [
  { id: 0, name: '-' },
  { id: 1, name: 'Tom Cook' },
  { id: 2, name: 'Wade Cooper' },
  { id: 3, name: 'Tanya Fox' },
  { id: 4, name: 'Arlene Mccoy' },
  { id: 5, name: 'Devon Webb' },
]

const programmi = [
  { id: 0, name: '-' },
  { id: 1, name: 'Programma 1' },
  { id: 2, name: 'Programma 2' },
  { id: 3, name: 'Programma 3' },
  { id: 4, name: 'Programma 4' },
  { id: 5, name: 'Programma 5' },
]

export default function Example() {
  const [selectedPerson, setSelectedPerson] = useState(people[0])
  const [selectedProgram, setSelectedProgram] = useState(programmi[0])

  return (
    <>
      <div className=' Wrap-Selettore flex flex-row gap-x-2 justify-center  pt-0'>
        {/* // PROGRAMMA */}
        <div className='  ml-6 w-52 pt-5'>
          <p className='Sezione text-zinc-900 antialiased underline underline-offset-6 ml-2'>Programma</p>
          <Listbox value={selectedProgram} onChange={setSelectedProgram}>
            <ListboxButton
              className={clsx(
                'relative block w-full rounded-t-xl rounded-b-none bg-white py-1.5 pr-8 pl-3 text-left text-sm/6 text-zinc-950',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-zink-950'
              )}
            >
              {selectedProgram.name}
              <ChevronDownIcon
                className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-zinc-950"
                aria-hidden="true"
              />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom"
              transition
              className={clsx(
                'w-[var(--button-width)] rounded-t-none border rounded-b-xl bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                'transition duration-100 ease-in-out data-[leave]:data-[closed]:opacity-0'
              )}
            >
              {programmi.map((programma) => (
                <ListboxOption
                  key={programma.name}
                  value={programma}
                  className="group flex cursor-default items-center gap-1 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-black/10"
                >
                  <CheckIcon className="invisible size-4 fill-black group-data-[selected]:visible" />
                  <div className="text-sm/6 text-zinc-950">{programma.name}</div>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        </div>

        {/* // AFFIDATO */}
        <div className="w-52 pt-5 mr-6">
          <p className="Sezione text-zinc-900 antialiased underline underline-offset-6 ml-2">Affidato</p>
          <Listbox value={selectedPerson} onChange={setSelectedPerson}>
            <ListboxButton
              className={clsx(
                'relative block w-full rounded-t-xl rounded-b-none bg-white py-1.5 pr-8 pl-3 text-left text-sm/6 text-zinc-950',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-zink-950'
              )}
            >
              {selectedPerson.name}
              <ChevronDownIcon
                className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-zinc-950"
                aria-hidden="true"
              />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom"
              transition
              className={clsx(
                'w-[var(--button-width)] rounded-t-none border rounded-b-xl bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                'transition duration-100 ease-in-out data-[leave]:data-[closed]:opacity-0'
              )}
            >
              {people.map((person) => (
                <ListboxOption
                  key={person.name}
                  value={person}
                  className="group flex cursor-default items-center gap-1 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-black/10"
                >
                  <CheckIcon className="invisible size-4 fill-black group-data-[selected]:visible" />
                  <div className="text-sm/6 text-zinc-950">{person.name}</div>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        </div>

      </div>
      {/* // CARTELLA CLINICA */}
      <div className='Cartella-Clinica bg-white border-black container mx-auto flex items-center w-[90%] sm:w-[75%] md:w-[65%] lg:w-[60%] min-w-[360px] justify-center mt-0 rounded-b-xl'>
        <div className='Cartella-Clinica-Body container mx-10 w-full overflow-hidden'>
          {/* ANAGRAFICA */}
          <div className="mt border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 pt-5 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 sm:py-10">
                <dt className="text-sm/6 font-medium text-gray-900">Nome</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">Margot Foster</dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Luogo e data di Nascita</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0"> Hawai </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">CF </dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">sisdfyghsdf </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Residenza</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Stato Civile</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Data Ingresso</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">ASL</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Prima Visita</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Ingresso</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Documento</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">T. Sanitaria</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">esenzioni ASL</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Referenti Familiari</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>

              {/* REFERENTI DSM */}
              <p className="Sezione text-sm/6 text-center mb-2 mt-5 font-bold text-black">Referenti DSM</p>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Psichiatra</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Ass, soc.:</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Psicologo/a</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>

              {/* Referenti Reverie */}
              <p className="Sezione text-sm/6 text-center mb-2 mt-5 font-bold text-black">Referenti Reverie</p>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Direttore Clinico</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Direttore Sanitario</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">UOB</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Op. Affidamento</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Tutor UOB</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Terapeuta individuale</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Terapeuta Familiare</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Diagnosi di Invio</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Diagnosi di Ingresso</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Diagnosi Attuale</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">li </dd>
              </div>

              {/* FAMILIARI */}
              <div className="Familiari overflow-x-auto my-5">
                <p className="Sezione Sezione text-sm/6 text-center font-bold text-black">Familiari</p>
                <table className="table-auto w-full divide-y-2 divide-gray-200 bg-white text-sm">
                  <thead className="ltr:text-left rtl:text-center mb-2 mt-5">
                    <tr>
                      <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Posizione</th>
                      <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Nome</th>
                      <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Eta'</th>
                      <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Professione</th>
                      <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Altro</th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200">
                    <tr>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">mamma</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">gatta</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">60</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">casalinga</td>
                    </tr>

                    <tr>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">papa</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">castoro</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">65</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">falegname</td>
                    </tr>

                    <tr>
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">zio</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">paperone</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">105</td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Accessori */}
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Storia</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                  qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                  pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>
                <dt className="text-sm/6 font-medium text-gray-900">Anamnesi</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                  qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                  pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>
                <dt className="text-sm/6 font-medium text-gray-900">Status</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                  qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                  pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>
                <dt className="text-sm/6 font-medium text-gray-900">Caratteristiche Invio</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                  qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                  pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>

              </div>
              {/* VALUTAZIONI STRUMENTALI */}
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">BPRS</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">80</dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">MMSE</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">3</dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Asse V Kennedy</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">5</dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">VGF</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">100</dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">GAF</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">5</dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Pericolosita</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">si</dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Altro</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">no</dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Diari Clinici</dt>
                <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
                      <div className="flex w-0 flex-1 items-center">
                        <PaperClipIcon aria-hidden="true" className="size-5 shrink-0 text-gray-400" />
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">data.pdf</span>
                          <span className="shrink-0 text-gray-400">2.4mb</span>
                        </div>
                      </div>
                      <div className="ml-4 shrink-0">
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Download
                        </a>
                      </div>
                    </li>
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
                      <div className="flex w-0 flex-1 items-center">
                        <PaperClipIcon aria-hidden="true" className="size-5 shrink-0 text-gray-400" />
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">data.pdf</span>
                          <span className="shrink-0 text-gray-400">4.5mb</span>
                        </div>
                      </div>
                      <div className="ml-4 shrink-0">
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                          Download
                        </a>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>

              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Note Attivita' terapeutiche di gruppo</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                  qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                  pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Note incontri familiari</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                  qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                  pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>
              </div>

              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Note incontri servizio</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                  qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                  pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>
              </div>

              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm/6 font-medium text-gray-900">Note rientri e attivita' esterne</dt>
                <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                  qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                  pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                </dd>
              </div>
            </dl >
          </div>
        </div>
      </div>
    </>
  )
}