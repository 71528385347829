// // // MapView.jsx
// import React, { useState, useEffect } from 'react';
// import { MapContainer, TileLayer, useMap } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import DOMPurify from 'dompurify';
// import Template from './Template';

// function SetViewOnClick({ coords }) {
//   const map = useMap();
//   map.setView(coords, map.getZoom());
//   return null;
// }

// function MapView() {
//   const [coords, setCoords] = useState([51.505, -0.09]); // Default coordinates

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         // DOMPurify.sanitize() is used to prevent XSS attacks
//         // DOMPurify.sanitize() returns a string, so we need to convert it to a number
//         const sanitizedLatitude = DOMPurify.sanitize(position.coords.latitude.toString());
//         const sanitizedLongitude = DOMPurify.sanitize(position.coords.longitude.toString());
//         setCoords([parseFloat(sanitizedLatitude), parseFloat(sanitizedLongitude)]);        
//       setCoords([position.coords.latitude, position.coords.longitude]);
//     },
// 	(error) => {
//       console.error("Error getting location", error);
//     });
//   }, []);

//   return (
//     <Template>
//       <MapContainer center={coords} zoom={13} style={{ height: "100vh", width: "100%" }}>
//         <SetViewOnClick coords={coords} />
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//         />
//       </MapContainer>
//     </Template>
//   );
// }

// import React, { useEffect } from 'react';
// import { useMap } from 'react-leaflet';
// import DOMPurify from 'dompurify';

// const MapView = () => {
//   const map = useMap();

//   useEffect(() => {
//     // Example URL for fetching content from OpenStreetMap
//     const url = 'https://a.tile.openstreetmap.org/13/4093/2723.png';

//     // Fetch content from OpenStreetMap or other external sources
//     fetch(url)
//       .then(response => response.blob())
//       .then(blob => {
//         // Create a URL for the blob
//         const objectURL = URL.createObjectURL(blob);

//         // Sanitize the URL (though in this case, it's a blob URL which is generally safe)
//         const sanitizedURL = DOMPurify.sanitize(objectURL);

//         // Use the sanitized URL in your application
//         const img = document.createElement('img');
//         img.src = sanitizedURL;
//         document.body.appendChild(img);

//         // Revoke the object URL after use to free up memory
//         URL.revokeObjectURL(objectURL);
//       })
//       .catch(error => console.error('Error fetching content:', error));
//   }, [map]);

//   return <div id="map" style={{ height: '100vh' }}></div>;
// };


import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import DOMPurify from 'dompurify';
import Template from './Template';

function SetViewOnClick({ coords }) {
  const map = useMap();
  map.setView(coords, map.getZoom());
  return null;
}

function MapView() {
  const [coords, setCoords] = useState([51.505, -0.09]); // Default coordinates

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const sanitizedLatitude = DOMPurify.sanitize(position.coords.latitude.toString());
        const sanitizedLongitude = DOMPurify.sanitize(position.coords.longitude.toString());
        setCoords([parseFloat(sanitizedLatitude), parseFloat(sanitizedLongitude)]);
      },
      (error) => {
        console.error("Error getting location", error);
      }
    );
  }, []);

  return (
    <Template>
      <div className="relative z-0">
        {/* MapContainer */}
        <MapContainer
          center={coords}
          zoom={13}
          style={{
            height: "calc(100vh - 3.5rem)", // Adjust height to leave space for the navbar
            width: "100%",
          }}
          className="z-0"
        >
          <SetViewOnClick coords={coords} />
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </MapContainer>
      </div>
    </Template>
  );
}

export default MapView;
