// DndContainer.jsx
import React from 'react';
// import { Droppable } from 'react-beautiful-dnd';
import Container from '../Element/Container';

class DndContainer extends React.Component {
  render() {
    return (
      <Droppable droppableId="dndContainer">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <Container>
              {this.props.children}
            </Container>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

export default DndContainer;