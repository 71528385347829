import React from 'react';
import DraggableElement from '../Element/Draggable';
import DndContainer from '../Element/DndContainer';
import './Window.css';

class Window extends React.Component {
    state = {
        active: false,
        zIndex: 0,
        dragging: false,
        initialX: 0,
        initialY: 0,
        offsetX: 0,
        offsetY: 0
    }
 
    handleTouchStart = (e) => {
        // Prevent default behavior
        e.preventDefault();
    
        // Convert touch event to mouse event and call handleMouseDown
        this.handleMouseDown(this.convertTouchToMouseEvent(e));
    }

    convertTouchToMouseEvent = (touchEvent) => {
        // Create a new mouse event based on the touch event
        return new MouseEvent('mousedown', {
            clientX: touchEvent.touches[0].clientX,
            clientY: touchEvent.touches[0].clientY
        });
    }
    
    handleMouseUp = () => {
        this.setState({ dragging: false });
        this.setState({ active: false });
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
        
        //
        document.removeEventListener('touchmove', this.handleMouseMove);
        document.removeEventListener('touchend', this.handleMouseUp);
    }
    
    handleMouseDown = (e) => {
        // Get the initial offset of the window
        const rect = this.elem.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;
    
        this.setState({
            dragging: true,
            initialX: offsetX,
            initialY: offsetY
        });
        this.setState(prevState => ({
            active: true,
            zIndex: prevState.zIndex + 1
        }));
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    
        //
        // Call the onMouseDown prop
        // this.onMouseDown();
        document.addEventListener('touchmove', this.handleMouseMove);
        document.addEventListener('touchend', this.handleMouseUp);
    }
    
    handleMouseMove = (e) => {
        if (e.type === 'touchmove') {
            e.preventDefault();
            e = this.convertTouchToMouseEvent(e);
        }
        if (this.state.dragging) {
            // Calculate the new offsets
            const newOffsetX = Math.max(0, Math.min(window.innerWidth - this.elem.offsetWidth, e.clientX - this.state.initialX));
            const newOffsetY = Math.max(0, Math.min(window.innerHeight - this.elem.offsetHeight, e.clientY - this.state.initialY));
    
            this.setState({
                offsetX: newOffsetX,
                offsetY: newOffsetY
            });
            this.elem.style.left = `${newOffsetX}px`;
            this.elem.style.top = `${newOffsetY}px`;
        }
    }

    handleResizeMouseDown = (e, direction) => {
        e.preventDefault();
        e.stopPropagation();
        // Start the resize operation
        this.resizing = true;
        this.resizeDirection = direction;
        this.startX = e.clientX;
        this.startY = e.clientY;
        this.startWidth = this.elem.offsetWidth;
        this.startHeight = this.elem.offsetHeight;
        const rect = this.elem.getBoundingClientRect();
        this.startLeft = rect.left;
        this.startTop = rect.top;
        // Ensure the position style is set
        this.elem.style.position = 'absolute';
        console.log(`MouseDown - direction: ${direction}, startX: ${this.startX}, startY: ${this.startY}, startWidth: ${this.startWidth}, startHeight: ${this.startHeight}, startLeft: ${this.startLeft}, startTop: ${this.startTop}`);
        document.addEventListener('mousemove', this.handleResizeMouseMove);
        document.addEventListener('mouseup', this.handleResizeMouseUp);
        // touch events
        document.addEventListener('touchmove', this.handleResizeMouseMove);
        document.addEventListener('touchend', this.handleResizeMouseUp);
    }

    handleResizeMouseMove = (e) => {
        // touch events
        if (e.type === 'touchmove') {
            e.preventDefault();
            e = this.convertTouchToMouseEvent(e);
        }
        //
        if (this.resizing) {
            const dx = this.resizeDirection === 'sw' || this.resizeDirection === 'nw' ? this.startX - e.clientX : e.clientX - this.startX;
            const dy = this.resizeDirection === 'nw' || this.resizeDirection === 'ne' ? this.startY - e.clientY : e.clientY - this.startY;
            console.log(`MouseMove - dx: ${dx}, dy: ${dy}`);
    
            if (this.resizeDirection === 'sw' || this.resizeDirection === 'nw') {
                const newLeft = this.startLeft + (e.clientX - this.startX);
                this.elem.style.left = `${newLeft}px`;
                this.elem.style.width = `${this.startWidth - (e.clientX - this.startX)}px`;
            }
            if (this.resizeDirection === 'sw' || this.resizeDirection === 'se') {
                this.elem.style.height = `${this.startHeight + dy}px`;
            }
            if (this.resizeDirection === 'nw' || this.resizeDirection === 'ne') {
                const newTop = this.startTop + (e.clientY - this.startY);
                this.elem.style.top = `${newTop}px`;
                this.elem.style.height = `${this.startHeight - (e.clientY - this.startY)}px`;
            }
            if (this.resizeDirection === 'se' || this.resizeDirection === 'ne') {
                this.elem.style.width = `${this.startWidth + dx}px`;
                this.elem.style.height = `${this.startHeight + dy}px`;
            }
    
            console.log(`MouseMove - new dimensions: ${this.elem.style.width} x ${this.elem.style.height}, new position: ${this.elem.style.left} x ${this.elem.style.top}`);
        }
    }

    handleResizeMouseUp = () => {
        this.setState({ dragging: false });
        // Stop the resize operation
        this.resizing = false;
        document.removeEventListener('mousemove', this.handleResizeMouseMove);
        document.removeEventListener('mouseup', this.handleResizeMouseUp);
        console.log('MouseUp - resize operation stopped');
        // touch events
        document.removeEventListener('touchmove', this.handleResizeMouseMove);
        document.removeEventListener('touchend', this.handleResizeMouseUp);
    }
    
    render() {
        return (
            <div className={`Window ${this.state.active ? 'active' : 'inactive'}`} 
                 ref={elem => this.elem = elem}
                 style={{ zIndex: this.state.zIndex }}>
                <div className="title-bar" 
                     onMouseDown={this.handleMouseDown} 
                     onMouseMove={this.handleMouseMove} 
                     onMouseUp={this.handleMouseUp}
                     onTouchStart={this.handleTouchStart}
                     onTouchMove={this.handleMouseMove}
                     onTouchEnd={this.handleMouseUp}>
                    <span className="title">Window Title</span>
                </div>
                <div className="resize-handle nw" 
                     onMouseDown={e => this.handleResizeMouseDown(e, 'nw')}
                     onTouchStart={e => this.handleResizeMouseDown(this.convertTouchToMouseEvent(e), 'nw')}></div>
                <div className="resize-handle ne" 
                     onMouseDown={e => this.handleResizeMouseDown(e, 'ne')}
                     onTouchStart={e => this.handleResizeMouseDown(this.convertTouchToMouseEvent(e), 'ne')}></div>
                <div className="resize-handle sw" 
                     onMouseDown={e => this.handleResizeMouseDown(e, 'sw')}
                     onTouchStart={e => this.handleResizeMouseDown(this.convertTouchToMouseEvent(e), 'sw')}></div>
                <div className="resize-handle se" 
                     onMouseDown={e => this.handleResizeMouseDown(e, 'se')}
                     onTouchStart={e => this.handleResizeMouseDown(this.convertTouchToMouseEvent(e), 'se')}></div>
                <div onMouseDown={this.handleMouseDown}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Window;