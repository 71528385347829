// Template.jsx

// This is a simple template component that wraps the children components in a div with the class name "View".
// This is useful for creating a consistent layout across the application.
// the exported component is wrapped with the withSanitizedDataUrls HOC, which sanitizes data URLs in the component.
// Template.jsx

// Template.jsx
import React from 'react';
import './Template.css';
import Sanitized from '../Feature/Sanitized';
import Cookies from 'js-cookie';
// import Sanitized from '../Feature/Sanitized';

// Set a cookie with SameSite=None and Secure=true
Cookies.set('csrftoken', 'your-csrf-token', {
  sameSite: 'None',
  secure: true,
});

function Template({ children }) {
  return (
    <div className="View">
      {children}
    </div>
  );
}

export default Sanitized(Template);