// // Icon.jsx
// import React, { forwardRef } from 'react';
// import DragAndDrop from '../Feature/DragAndDrop';
// import * as Icons from 'react-icons/fa';

// const Icon = forwardRef((props, ref) => {
//     // console.log("(COMPOUND ICON) logging props", props); // This will log the props
//     const IconComponent = Icons[`Fa${props.icon.charAt(0).toUpperCase() + props.icon.slice(1)}`];
//     // console.log("Icon Component:", IconComponent);
//     // console.log("> with ref:", ref); // Add this line
//     // console.log("> with props:", props); // Add this line
//     // return IconComponent ? <IconComponent ref={ref} /> : null;
//     return (
//       <div className='Icon'>
//         {IconComponent ? <IconComponent size={props.size} ref={ref} /> : null}
//       </div>
//     );
// });

// export default DragAndDrop(Icon);

// Icon.jsx
// import React, { forwardRef } from 'react';
// import DragAndDrop from '../Feature/DragAndDrop';
// import * as Icons from 'react-icons/fa';

// const Icon = forwardRef((props, ref) => {
//     const IconComponent = Icons[`Fa${props.icon.charAt(0).toUpperCase() + props.icon.slice(1)}`];
//     return (
//       <div className='Icon' ref={ref}>
//         {IconComponent ? <IconComponent size={props.size} /> : null}
//       </div>
//     );
// });

// export default DragAndDrop(Icon);

// BEAUTIFUL DND
import React from 'react';
import DraggableElement from '../Element/Draggable';
import * as Icons from 'react-icons/fa';

function Icon({ id, icon, size, index }) {
  const IconComponent = Icons[`Fa${icon.charAt(0).toUpperCase() + icon.slice(1)}`];

  console.log("inside Icon instance debug:", index, typeof index);
  return (
    <DraggableElement id={id} index={index}>
      <div className='Icon'>
        {IconComponent ? <IconComponent size={size} /> : null}
      </div>
    </DraggableElement>
  );
}

export default Icon;