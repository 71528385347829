import React, { useState } from 'react';
import './TabContainer.css';

function Tab({ children, activeTab, index, setActiveTab }) {
  return (
    <div
      className={`tab ${activeTab === index ? 'active' : ''}`}
      onClick={() => setActiveTab(index)}
    >
      {children}
    </div>
  );
}

function TabBar({ children, activeTab, setActiveTab }) {
  return (
    <div className="tab-bar">
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, { activeTab, index, setActiveTab })
      )}
    </div>
  );
}

function TabContainer({ children, activeTab }) {
  const childArray = Array.isArray(children) ? children : [children];
  const childToRender = childArray[activeTab] !== undefined ? childArray[activeTab] : childArray[0];
  return (
      <div className="tab-container">
          {childToRender}
      </div>
  );
}

export { Tab, TabContainer, TabBar };