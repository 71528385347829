// import React, { createContext, useState, useEffect } from 'react';
// import Cookies from 'js-cookie';
// import Modal from 'react-modal';

// const UserContext = createContext();

// const UserProvider = ({ children }) => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [user, setUser] = useState({ name: '', email: '' });
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [modalContent, setModalContent] = useState('');

//   const checkLoginStatus = () => {
//     console.log('Checking login status...');
//     fetch('/api/validate-session/', {
//       method: 'GET',
//       credentials: 'include',
//     })
//       .then((res) => {
//         console.log('Response received:', res);
//         const contentType = res.headers.get('content-type');
//         if (contentType && contentType.includes('application/json')) {
//           return res.json().then((data) => {
//             console.log('Data received:', data);
//             if (data.status === 'valid') {
//               console.log('Session is valid. User is logged in.');
//               setIsLoggedIn(true);
//               setUser({ name: data.name, email: data.email }); // Assuming the API returns user info
//             } else {
//               console.log('Session is invalid. User is not logged in.');
//               setIsLoggedIn(false);
//               setUser({ name: '', email: '' });
//             }
//           });
//         } else {
//           return res.text().then((html) => {
//             console.log('Received HTML:', html);
//             setModalContent(html);
//             setModalIsOpen(true);
//           });
//         }
//       })
//       .catch((error) => {
//         console.error('Error validating session:', error);
//         setIsLoggedIn(false);
//         setUser({ name: '', email: '' });
//       });
//   };
  
//   const handleError = (status, message) => {
//     switch (status) {
//       case 400:
//         alert(`Bad Request: ${message}`);
//         break;
//       case 401:
//         alert(`Unauthorized: ${message}`);
//         break;
//       case 403:
//         alert(`Forbidden: ${message}`);
//         break;
//       case 500:
//       default:
//         alert(`Internal Server Error: ${message}`);
//         break;
//     }
//   };
//   const login = (userInfo) => {
//     console.log('Logging in user:', userInfo);
//     setUser(userInfo);
//     setIsLoggedIn(true);
//   };

//   const logout = () => {
//     console.log('Logging out user.');
//     fetch('/api/logout/', {
//       method: 'POST',
//       credentials: 'include',
//       headers: {
//         'X-CSRFToken': Cookies.get('csrftoken'),
//       },
//     })
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.status === 'success') {
//           Cookies.remove('sessionid'); // Assuming 'sessionid' is the session cookie name
//           setUser({ name: '', email: '' });
//           setIsLoggedIn(false);
//         } else {
//           console.error('Error logging out:', data.message);
//         }
//       })
//       .catch((error) => {
//         console.error('Error logging out:', error);
//       });
//   };

//   useEffect(() => {
//     checkLoginStatus();
//   }, []);

//   return (
//     <UserContext.Provider value={{ isLoggedIn, user, login, logout }}>
//       {children}
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={() => setModalIsOpen(false)}
//         contentLabel="Error"
//       >
//         <div dangerouslySetInnerHTML={{ __html: modalContent }} />
//         <button onClick={() => setModalIsOpen(false)}>Close</button>
//       </Modal>
//     </UserContext.Provider>
//   );
// };

// export { UserContext, UserProvider };
import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({ name: '', email: '' });

  const checkLoginStatus = () => {
    console.log('Checking login status...');
    fetch('/api/validate-session/', {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => {
        console.log('Response received:', res);
        const contentType = res.headers.get('content-type');
  
        // Handle valid JSON response
        if (res.ok && contentType && contentType.includes('application/json')) {
          return res.json().then((data) => {
            console.log('Data received:', data);
            if (data.status === 'valid') {
              console.log('Session is valid. User is logged in.');
              setIsLoggedIn(true);
              setUser({ name: data.name, email: data.email });
            } else {
              console.log('Session is invalid. User is not logged in.');
              setIsLoggedIn(false);
              setUser({ name: '', email: '' });
            }
          });
        }
  
        // Skip opening a popup for valid redirects to HTML pages
        if (res.redirected && contentType && contentType.includes('text/html')) {
          console.log('Redirected response ignored:', res.url);
          return; // Do nothing for redirected HTML responses
        }
  
        // Open popup only for unexpected HTML errors
        if (!res.ok && contentType && contentType.includes('text/html')) {
          return res.text().then((html) => {
            console.warn('Error response received with HTML:', res.status);
            openPopup(html); // Show the error HTML in a popup
          });
        }
  
        // Log unexpected responses
        console.error('Unexpected response type:', res);
      })
      .catch((error) => {
        console.error('Error validating session:', error);
        setIsLoggedIn(false);
        setUser({ name: '', email: '' });
      });
  };
  
  

  const handleError = (status, message) => {
    switch (status) {
      case 400:
        alert(`Bad Request: ${message}`);
        break;
      case 401:
        alert(`Unauthorized: ${message}`);
        break;
      case 403:
        alert(`Forbidden: ${message}`);
        break;
      case 500:
      default:
        alert(`Internal Server Error: ${message}`);
        break;
    }
  };

  const login = (userInfo) => {
    console.log('Logging in user:', userInfo);
    setUser(userInfo);
    setIsLoggedIn(true);
  };

  const logout = () => {
    console.log('Logging out user.');
    fetch('/api/logout/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'success') {
          Cookies.remove('sessionid'); // Assuming 'sessionid' is the session cookie name
          setUser({ name: '', email: '' });
          setIsLoggedIn(false);
        } else {
          console.error('Error logging out:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error logging out:', error);
      });
  };

  const openPopup = (content) => {
    const popup = window.open('', 'Error', 'width=600,height=400');
    if (popup) {
      const formattedContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Popup</title>
        </head>
        <body>
          ${content}
        </body>
        </html>
      `;
      popup.document.open();
      popup.document.write(formattedContent);
      popup.document.close();
      popup.focus();
    } else {
      console.error('Popup blocked by browser');
    }
  };
  

  useEffect(() => {
    checkLoginStatus();
  }, []);

  return (
    <UserContext.Provider value={{ isLoggedIn, user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
