import React from 'react';
import ReactDOM from 'react-dom/client';
// import { StyleSheetManager } from 'styled-components';
// import nonceStylisPlugin from './Components/Compound/nonceStylisPlugin';
import App from './App.jsx';
import './index.css'; // Import Tailwind CSS

const nonceMetaTag = document.querySelector('meta[name="csp-nonce"]');
const nonce = nonceMetaTag ? nonceMetaTag.getAttribute('content') : null;

console.log("Nonce passed to StyleSheetManager:", nonce);

ReactDOM.createRoot(document.getElementById('root')).render(
  // <StyleSheetManager stylisPlugins={[nonceStylisPlugin]} stylisOptions={{ nonce }}>
  <>
    <App />
  </>
  // </StyleSheetManager>
);

