// Container.jsx
import React from 'react';
import './Container.css';

class Container extends React.Component {
  handleDrop = (sourceId, targetId) => {
    console.log(`Dropped component with id ${sourceId} onto component with id ${targetId}`);
  };

  render() {
    return (
      <div className='basicContainer'>
        {this.props.children}
      </div>
    );
  }
}

export default Container;