// FECV.jsx
import React from 'react';
import DndContainer from '../Element/DndContainer';
import Icon from '../Compound/Icon';
import { FaHome, FaUser, FaCog } from 'react-icons/fa';
import Window from '../Compound/Window';

function FECV() {
  return (
      <DndContainer>
        <Window>
        {/* <FaHome />
        <FaUser />
        <FaCog /> */}
        <Icon id="icon1" icon="Home" size={60} index={0}/>
        <Icon id="icon2" icon="User" size={60} index={1}/>
        <Icon id="icon3" icon="Cog" size={120} index={2}/>
        </Window>
        <Window>
        {/* <FaHome />
        <FaUser />
        <FaCog /> */}
        <Icon id="icon4" icon="Home" size={60} index={3}/>
        <Icon id="icon5" icon="User" size={60} index={4}/>
        <Icon id="icon6" icon="Cog" size={120} index={5}/>
        </Window>
      </DndContainer>
  );
}

export default FECV;