// DraggableElement.jsx
// import React from 'react';
// import withDragAndDrop from '../Feature/DragAndDrop';

// function DraggableElement({ id, handleDrop, children }) {
//   return <div>{children}</div>;
// }

// export default withDragAndDrop(DraggableElement);

// BEAUTIFUL DND
// DraggableElement.jsx
import React from 'react';
// import { Draggable } from 'react-beautiful-dnd';

function DraggableElement({ id, index, children }) {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{ ...provided.draggableProps.style, opacity: snapshot.isDragging ? 0.5 : 1 }}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
}

export default DraggableElement;