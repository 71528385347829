// // feature/Sanitized.jsx
// import React, { useEffect } from 'react';
// import DOMPurify from 'dompurify';

// const withSanitization = (WrappedComponent) => {
//   return (props) => {
//     useEffect(() => {
//       // Function to sanitize data URLs
//       const sanitizeDataUrls = () => {
//         const elements = document.querySelectorAll('[src^="data:"]');
//         elements.forEach((element) => {
//           const sanitizedUrl = DOMPurify.sanitize(element.src);
//           console.log(`Sanitized data URL: ${element.src}`);
//           element.src = sanitizedUrl;
//         });
//       };

//       // Sanitize data URLs on initial load
//       sanitizeDataUrls();

//       // Optionally, you can set up a MutationObserver to sanitize dynamically added data URLs
//       const observer = new MutationObserver((mutations) => {
//         mutations.forEach((mutation) => {
//           if (mutation.type === 'attributes' && mutation.attributeName === 'src' && mutation.target.src.startsWith('data:')) {
//             const sanitizedUrl = DOMPurify.sanitize(mutation.target.src);
//             console.log(`Sanitized data URL: ${mutation.target.src}`);
//             mutation.target.src = sanitizedUrl;
//           }
//         });
//       });

//       observer.observe(document.body, {
//         attributes: true,
//         subtree: true,
//         attributeFilter: ['src'],
//       });

//       // Cleanup observer on unmount
//       return () => observer.disconnect();
//     }, []);

//     // Sanitize HTML content before rendering
//     const sanitizedProps = { ...props };
//       if (sanitizedProps.htmlContent) {
//         sanitizedProps.htmlContent = DOMPurify.sanitize(sanitizedProps.htmlContent);
//       }
//     return <WrappedComponent {...props} />;
//   };
// };

// export default withSanitization;

// feature/Sanitized.jsx
import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

const Sanitized = (WrappedComponent) => {
  return (props) => {
    const [sanitizedProps, setSanitizedProps] = useState({ ...props });

    useEffect(() => {
      // Function to sanitize data URLs
      const sanitizeDataUrls = () => {
        const elements = document.querySelectorAll('[src^="data:"]');
        elements.forEach((element) => {
          const sanitizedUrl = DOMPurify.sanitize(element.src);
          console.log(`Sanitized data URL: ${element.src}`);
          element.src = sanitizedUrl;
        });
      };

      // Sanitize data URLs on initial load
      sanitizeDataUrls();

      // Optionally, you can set up a MutationObserver to sanitize dynamically added data URLs
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'src' && mutation.target.src.startsWith('data:')) {
            const sanitizedUrl = DOMPurify.sanitize(mutation.target.src);
            console.log(`Sanitized data URL: ${mutation.target.src}`);
            mutation.target.src = sanitizedUrl;
          }
        });
      });

      observer.observe(document.body, {
        attributes: true,
        subtree: true,
        attributeFilter: ['src'],
      });

      // Sanitize HTML content before rendering
      const newSanitizedProps = { ...props };
      if (newSanitizedProps.htmlContent) {
        newSanitizedProps.htmlContent = DOMPurify.sanitize(newSanitizedProps.htmlContent);
      }
      setSanitizedProps(newSanitizedProps);

      // Cleanup observer on unmount
      return () => observer.disconnect();
    }, [props]);

    return <WrappedComponent {...sanitizedProps} />;
  };
};

export default Sanitized;