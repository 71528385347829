// import React, { useEffect, useState } from 'react';
// import DOMPurify from 'dompurify';
// import Template from './Template';
// import { Tab, TabContainer, TabBar } from '../Element/TabContainer';

// function Djtemplate() {
//     const [activeTab, setActiveTab] = useState(0);
//     const [templateContent, setTemplateContent] = useState('');

//     useEffect(() => {
//         // Fetch template content from Django backend
//         const fetchTemplateContent = async () => {
//             try {
//                 const response = await fetch('/api/template-content/');
//                 if (response.ok) {
//                     const data = await response.text();
//                     // Sanitize the HTML content
//                     const sanitizedContent = DOMPurify.sanitize(data);
//                     setTemplateContent(sanitizedContent);
//                 } else {
//                     console.error('Failed to fetch template content');
//                 }
//             } catch (error) {
//                 console.error('Error fetching template content:', error);
//             }
//         };

//         fetchTemplateContent();
//     }, [activeTab]);

//     return (
//         <Template>
//             <TabBar activeTab={activeTab} setActiveTab={setActiveTab}>
//                 <Tab>Template 1</Tab>
//                 <Tab>Template 2</Tab>
//             </TabBar>
//             <TabContainer activeTab={activeTab}>
//                 <div className="template-content" dangerouslySetInnerHTML={{ __html: templateContent }} />
//             </TabContainer>
//         </Template>
//     );
// }

// export default Djtemplate;

import React, { useEffect, useState } from 'react';
import Template from './Template';
import { Tab, TabContainer, TabBar } from '../Element/TabContainer';
// import withSanitization from './Sanitized';

function Djtemplate({ htmlContent }) {
    const [activeTab, setActiveTab] = useState(0);
    const [templateContent, setTemplateContent] = useState(htmlContent || '');

    useEffect(() => {
        // Fetch template content from Django backend
        const fetchTemplateContent = async () => {
            try {
                const response = await fetch('/api/template-content/');
                if (response.ok) {
                    console.log('awaiting response');
                    const data = await response.text();
                    setTemplateContent(data);
                } else {
                    console.error('Failed to fetch template content');
                }
            } catch (error) {
                console.error('Error fetching template content:', error);
            }
        };

        fetchTemplateContent();
    }, [activeTab]);

    return (
        <Template>
            <TabBar activeTab={activeTab} setActiveTab={setActiveTab}>
                <Tab>Template 1</Tab>
                <Tab>Template 2</Tab>
            </TabBar>
            <TabContainer activeTab={activeTab}>
                <div className='template-title'>Template Content</div>
                <div className="template-content" dangerouslySetInnerHTML={{ __html: templateContent }} />
            </TabContainer>
        </Template>
    );
}

export default Djtemplate;