import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { create } from 'xmlbuilder2';
import { XMLValidator } from 'fast-xml-parser';

const ConvertitoreXML = () => {
  const [xmlData, setXmlData] = useState('');
  const [validationResult, setValidationResult] = useState('');

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const root = create({ version: '1.0' }).ele('root');
      json.forEach((row, rowIndex) => {
        const rowElem = root.ele('row', { index: rowIndex });
        row.forEach((cell, cellIndex) => {
          rowElem.ele('cell', { index: cellIndex }).txt(cell);
        });
      });

      const xml = root.end({ prettyPrint: true });
      setXmlData(xml);
    };

    reader.readAsArrayBuffer(file);
  };

  const validateXml = () => {
    try {
      const isValid = XMLValidator.validate(xmlData);
      if (isValid === true) {
        setValidationResult('XML is valid.');
      } else {
        setValidationResult(`Validation Error: ${isValid.err.msg}`);
      }
    } catch (err) {
      setValidationResult(`Validation Error: ${err.message}`);
    }
  };

  const exportXml = () => {
    const blob = new Blob([xmlData], { type: 'application/xml' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'output.xml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="XMLView min-h-screen flex flex-col p-4 overflow-auto pb-14">
      <div className="container bg-white rounded shadow p-4 mb-4">
        <p>Caricamento File Excel (.xlsx, .xls):</p>
        <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
        <p>
          Il file viene caricato in memoria temporanea e non persiste al
          ricaricamento della pagina. La conversione in XML e' istantanea, ma la
          validazione del file XML richiede una validazione tramite XSD, da
          caricare.
        </p>
      </div>
      <div className="container bg-white rounded shadow p-4 mb-4">
        <p>Il seguente bottone E' un segnaposto per l'esecuzione della validazione
          del file XML secondo le regole definite dal file XSD caricato
        </p>
        <button
          onClick={validateXml}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Validazione XML
        </button>
      </div>
      <div className="container bg-white rounded shadow p-4 mb-4">
        <p>Visualizzazione del file Excel convertito in XML</p>
        <textarea
          value={xmlData}
          readOnly
          rows="20"
          className="w-full p-2 border rounded"
        />
        <p>La conversione in XML non include la validazione</p>
      </div>
      <div className="container bg-white rounded shadow p-4 mb-4">
        <p>Di seguito descrizione del risultato del processo di Validazione</p>
        <p>{validationResult}</p>
      </div>
    </div>
  );
};

export default ConvertitoreXML;
