// // ########## REACT COMPONENTS ######################################################
// import React, { useState, useEffect } from 'react'
// import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
// // import { DndProvider } from 'react-dnd';
// // import { DragDropContext } from 'react-beautiful-dnd';
// // import { HTML5Backend } from 'react-dnd-html5-backend';
// // ########## STYLE AND ASSETS ######################################################
// import reactLogo from './Assets/react.svg'
// import viteLogo from '/vite.svg'
// import './App.css'
// // ########## COMPONENTS ############################################################
// import UserContext from './Contexts/ContextWrapper';
// import Navbar from './Components/Compound/Navbar';
// // import SedEtiam from './Components/View/SedEtiam';
// import ContextWrapper from './Contexts/ContextWrapper';
// import Login from './Components/View/Login';
// import CartelleCliniche from './Components/View/CartelleCliniche';
// import Djtemplate from './Components/View/djtemplate';
// import FECV from './Components/View/FECV';
// import MapView  from './Components/View/MapView';
// import GoogleAuthRedirect from './Components/View/GoogleAuthRedirect';
// // import TabContainer from './Components/TabContainer';
// import ConvertitoreXML from './Components/View/ConvertitoreXML';
// import Template from './Components/View/Template';
// import NavbarX from './Components/Compound/NavbarX';
// // ##################################################################################

// // ERROR HANDLING
// class ErrorBoundary extends React.Component {
// 	constructor(props) {
// 	  super(props);
// 	  this.state = { hasError: false };
// 	}

// 	static getDerivedStateFromError(error) {
// 	  // Update state so the next render will show the fallback UI.
// 	  return { hasError: true };
// 	}

// 	componentDidCatch(error, errorInfo) {
// 	  // You can also log the error to an error reporting service
// 	  console.log(error, errorInfo);
// 	}

// 	render() {
// 	  if (this.state.hasError) {
// 		// You can render any custom fallback UI
// 		return <h1>Something went wrong.</h1>;
// 	  }
// 	  return this.props.children; 
// 	}
//   }
// //   

// function Content() {
//     // const location = useLocation();

//     return (
//         <div className="Content bg-gray-100 min-h-screen p-4" key={location.pathname}>
//             <Routes>
//                 {/* <Route path="/sed-etiam" element={<SedEtiam />} /> */}
//                 <Route path="/login" element={<Login />} />
// 				<Route path="/cartelle-cliniche" element={<CartelleCliniche />} />
// 				<Route path="/FECV" element={<FECV />} />
// 				<Route path="/Map" element={<MapView />} />
// 				<Route path="/GoogleAuthRedirect" element={<GoogleAuthRedirect />} />
// 				<Route path='/convertitoreXML' element={<ConvertitoreXML />} />
// 				<Route path="/djtemplate" element={<Djtemplate />} />
//             </Routes>
//         </div>
//     );
// }

// function App() {
// 	// const location = useLocation();

// 	// function to get the nonce from the meta tag
// 	// const getNonce = () => {
// 	// 	const metaTag = document.querySelector('meta[name="csp-nonce"]');
// 	// 	return metaTag ? metaTag.content : null;
// 	//   };

//     const onDragEnd = (result) => {
//         // The result object will have information about the drag and drop event
//         // See https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/responders.md
//     };

// 	return (
// 		<ErrorBoundary>
// 			{/* <DragDropContext onDragEnd={onDragEnd}> */}
// 				<ContextWrapper>
// 					<Router>
// 						<div className="App" /*This is a div wrap for the Content Element */ >
// 							<Content />
// 						</div>
// 						<div className="NavbarX" /* This is a div wrap for the Navbar Compound */>
// 							<NavbarX />
// 						</div>
// 					</Router>
// 				</ContextWrapper>
// 			{/* </DragDropContext> */}
// 		</ErrorBoundary>
// 	);
// }

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from './Contexts/UserContext'; // Import UserProvider
import NavbarX from "./Components/Compound/NavbarX";
import Login from './Components/View/Login';
import CartelleCliniche from './Components/View/CartelleCliniche';
import Djtemplate from './Components/View/djtemplate';
import FECV from './Components/View/FECV';
import MapView from './Components/View/MapView';
import GoogleAuthRedirect from './Components/View/GoogleAuthRedirect';
import ConvertitoreXML from './Components/View/ConvertitoreXML';
import UserList from "./Components/View/UsersList";

function App() {
    return (
        <UserProvider>
            <Router>
                {/* Full-screen container */}
                <div className="Desktop relative min-h-screen bg-green-900">
                    {/* Navbar */}
                    <NavbarX />
                    {/* Content Section */}
                    <div className="Content w-full pb-14">
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/cartelle-cliniche" element={<CartelleCliniche />} />
                            <Route path="/FECV" element={<FECV />} />
                            <Route path="/Map" element={<MapView />} />
                            <Route path="/GoogleAuthRedirect" element={<GoogleAuthRedirect />} />
                            <Route path="/convertitoreXML" element={<ConvertitoreXML />} />
                            <Route path="/djtemplate" element={<Djtemplate />} />
                            <Route path="/users" element={<UserList />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </UserProvider>
    );
}

export default App;


