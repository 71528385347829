// import React, { useState } from "react";
// import { Link } from "react-router-dom";

// const NavbarX = () => {
//     const [menuOpen, setMenuOpen] = useState(false);

//     const toggleMenu = () => {
//         setMenuOpen(!menuOpen);
//     };

//     return (
//         <div className="NavbarX fixed bottom-0 left-0 w-full bg-gray-800 text-white h-14 flex items-center justify-between px-4 z-50 shadow-lg">
//             {/* Start Button */}
//             <div className="relative">
//                 <button
//                     onClick={toggleMenu}
//                     className="w-10 h-10 bg-gray-700 rounded hover:bg-gray-600 flex items-center justify-center"
//                     aria-label="Start Button"
//                 >
//                     S
//                 </button>

//                 {/* Dropdown Menu with Animation */}
//                 <div
//                     className={`absolute bottom-full left-0 bg-gray-900 text-white shadow-md mb-2 rounded z-50 p-2 transition-transform duration-500 transform origin-bottom-left ${
//                         menuOpen
//                             ? "opacity-100 scale-100 visible"
//                             : "opacity-0 scale-95 invisible"
//                     }`}
//                 >
//                     <ul>
//                         <li>
//                             <Link
//                                 to="/cartelle-cliniche"
//                                 className="block px-4 py-2 hover:bg-gray-700 rounded"
//                                 onClick={() => setMenuOpen(false)}
//                             >
//                                 Cartelle Cliniche
//                             </Link>
//                         </li>
//                         <li>
//                             <Link
//                                 to="/FECV"
//                                 className="block px-4 py-2 hover:bg-gray-700 rounded"
//                                 onClick={() => setMenuOpen(false)}
//                             >
//                                 FECV
//                             </Link>
//                         </li>
//                         <li>
//                             <Link
//                                 to="/Map"
//                                 className="block px-4 py-2 hover:bg-gray-700 rounded"
//                                 onClick={() => setMenuOpen(false)}
//                             >
//                                 Map
//                             </Link>
//                         </li>
//                         <li>
//                             <Link
//                                 to="/GoogleAuthRedirect"
//                                 className="block px-4 py-2 hover:bg-gray-700 rounded"
//                                 onClick={() => setMenuOpen(false)}
//                             >
//                                 Google Auth Redirect
//                             </Link>
//                         </li>
//                         <li>
//                             <Link
//                                 to="/convertitoreXML"
//                                 className="block px-4 py-2 hover:bg-gray-700 rounded"
//                                 onClick={() => setMenuOpen(false)}
//                             >
//                                 Convertitore XML
//                             </Link>
//                         </li>
//                         <li>
//                             <Link
//                                 to="/djtemplate"
//                                 className="block px-4 py-2 hover:bg-gray-700 rounded"
//                                 onClick={() => setMenuOpen(false)}
//                             >
//                                 Djtemplate
//                             </Link>
//                         </li>
//                     </ul>
//                 </div>
//             </div>

//             {/* Navigation Buttons */}
//             <div className="flex gap-4">
//                 <Link
//                     to="/cartelle-cliniche"
//                     className="w-8 h-8 bg-gray-700 rounded hover:bg-gray-600 flex items-center justify-center"
//                 >
//                     C
//                 </Link>
//                 <Link
//                     to="/FECV"
//                     className="w-8 h-8 bg-gray-700 rounded hover:bg-gray-600 flex items-center justify-center"
//                 >
//                     F
//                 </Link>
//                 <Link
//                     to="/Map"
//                     className="w-8 h-8 bg-gray-700 rounded hover:bg-gray-600 flex items-center justify-center"
//                 >
//                     M
//                 </Link>
//             </div>
//         </div>
//     );
// };

// export default NavbarX;
// import React from "react";
// import { Link } from "react-router-dom";
// import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
// import { ChevronDownIcon } from "@heroicons/react/20/solid";

// const NavbarX = () => {
//   return (
//     <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white h-20 flex items-center justify-between px-4 z-50 shadow-lg">
//       {/* Start Button with Animated Dropdown */}
//       <Menu as="div" className="relative inline-block text-left">
//         <MenuButton className="inline-flex items-center gap-x-1.5 rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600">
//           Start
//           <ChevronDownIcon
//             aria-hidden="true"
//             className="-mr-1 h-5 w-5 text-gray-400"
//           />
//         </MenuButton>

//         {/* Wrapper ensures position is applied before animation */}
//         <div className="absolute left-0 bottom-full mb-2 z-10">
//           <Transition
//             as="div"
//             enter="transition ease-out duration-300 transform"
//             enterFrom="opacity-0 scale-95"
//             enterTo="opacity-100 scale-100"
//             leave="transition ease-in duration-200 transform"
//             leaveFrom="opacity-100 scale-100"
//             leaveTo="opacity-0 scale-95"
//           >
//             <MenuItems
//               className="origin-bottom-left rounded-md bg-gray-900 text-white shadow-lg ring-1 ring-black/5 focus:outline-none"
//             >
//               <div className="py-1">
//                 <MenuItem>
//                   {({ active }) => (
//                     <Link
//                       to="/cartelle-cliniche"
//                       className={`block px-4 py-2 text-sm ${
//                         active ? "bg-gray-700 text-white" : "text-gray-400"
//                       }`}
//                     >
//                       Cartelle Cliniche
//                     </Link>
//                   )}
//                 </MenuItem>
//                 <MenuItem>
//                   {({ active }) => (
//                     <Link
//                       to="/FECV"
//                       className={`block px-4 py-2 text-sm ${
//                         active ? "bg-gray-700 text-white" : "text-gray-400"
//                       }`}
//                     >
//                       FECV
//                     </Link>
//                   )}
//                 </MenuItem>
//                 <MenuItem>
//                   {({ active }) => (
//                     <Link
//                       to="/Map"
//                       className={`block px-4 py-2 text-sm ${
//                         active ? "bg-gray-700 text-white" : "text-gray-400"
//                       }`}
//                     >
//                       Map
//                     </Link>
//                   )}
//                 </MenuItem>
//               </div>
//             </MenuItems>
//           </Transition>
//         </div>
//       </Menu>

//       {/* Other Navigation Buttons */}
//       <div className="flex gap-4">
//         <Link
//           to="/GoogleAuthRedirect"
//           className="w-8 h-8 bg-gray-700 rounded hover:bg-gray-600 flex items-center justify-center"
//         >
//           G
//         </Link>
//         <Link
//           to="/convertitoreXML"
//           className="w-8 h-8 bg-gray-700 rounded hover:bg-gray-600 flex items-center justify-center"
//         >
//           XML
//         </Link>
//         <Link
//           to="/djtemplate"
//           className="w-8 h-8 bg-gray-700 rounded hover:bg-gray-600 flex items-center justify-center"
//         >
//           D
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default NavbarX;
// import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
// import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
// import { Link, NavLink } from 'react-router-dom'

// import loggedUser from '../../Assets/avatar-green.jpg'
// import loggedOutUser from '../../Assets/avatar-grey.jpg'

// const user = {
//   name: 'Tom Cook',
//   email: 'tom@example.com',
//   imageUrl: loggedUser,
// }

// const navigation = [
//   { name: 'Cartelle Cliniche', to: '/cartelle-cliniche', current: true },
//   { name: 'Pazienti', to: '/pazienti', current: false },
//   { name: 'Strumenti', to: '/strumenti', current: false },
//   { name: 'Calendario', to: '/calendario', current: false },
//   { name: 'Segnalazioni', to: '/segnalazioni', current: false },
// ]

// const userNavigation = [
//   { name: 'Profilo', to: '/profilo' },
//   { name: 'Impostazioni', to: '/impostazioni' },
//   { name: 'Disconnessione', to: '/logout' },
// ]

// const NouserNavigation = [
//   { name: 'Accedi', to: '/login' },
// ]

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

// export default function Example() {
//   return (
//     <>
//       <div className="min-h-full sticky top-0 z-50">
//         <Disclosure as="nav" className="bg-gray-800">
//           <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//             <div className="flex h-16 items-center justify-between">
//               <div className="flex items-center">
//                 <div className="hidden md:block">
//                   <div className="ml-8 flex items-baseline space-x-4">
//                     {navigation.map((item) => (
//                       <NavLink
//                         key={item.name}
//                         to={item.to}
//                         className={({ isActive }) =>
//                           classNames(
//                             isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
//                             'rounded-md px-3 py-2 text-sm font-medium'
//                           )
//                         }
//                       >
//                         {item.name}
//                       </NavLink>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//               <div className="hidden md:block">
//                 <div className="ml-4 flex items-center md:ml-6">
//                   <button
//                     type="button"
//                     className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
//                   >
//                     <BellIcon aria-hidden="true" className="size-6" />
//                   </button>
//                   <Menu as="div" className="relative ml-3">
//                     <MenuButton className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
//                       <img alt="" src={user.imageUrl} className="size-8 rounded-full" />
//                     </MenuButton>
//                     <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5">
//                       {userNavigation.map((item) => (
//                         <MenuItem key={item.name}>
//                           <NavLink
//                             to={item.to}
//                             className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
//                           >
//                             {item.name}
//                           </NavLink>
//                         </MenuItem>
//                       ))}
//                     </MenuItems>
//                   </Menu>
//                 </div>
//               </div>
//               <div className="-mr-2 flex md:hidden">
//                 <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
//                   <Bars3Icon aria-hidden="true" className="block size-6 group-data-[open]:hidden" />
//                   <XMarkIcon aria-hidden="true" className="hidden size-6 group-data-[open]:block" />
//                 </DisclosureButton>
//               </div>
//             </div>
//           </div>

//           <DisclosurePanel className="md:hidden ">
//             <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
//               {navigation.map((item) => (
//                 <DisclosureButton
//                   key={item.name}
//                   as={Link}
//                   to={item.to}
//                   className={classNames(
//                     item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
//                     'block rounded-md px-3 py-2 text-base font-medium'
//                   )}
//                 >
//                   {item.name}
//                 </DisclosureButton>
//               ))}
//             </div>
//             <div className="border-t border-gray-700 pb-3 pt-4">
//               <div className="flex items-center px-5">
//                 <img alt="" src={user.imageUrl} className="size-10 rounded-full" />
//                 <div className="ml-3">
//                   <div className="text-base font-medium text-white">{user.name}</div>
//                   <div className="text-sm font-medium text-gray-400">{user.email}</div>
//                 </div>
//               </div>
//               <div className="mt-3 space-y-1 px-2">
//                 {userNavigation.map((item) => (
//                   <DisclosureButton
//                     key={item.name}
//                     as={Link}
//                     to={item.to}
//                     className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
//                   >
//                     {item.name}
//                   </DisclosureButton>
//                 ))}
//               </div>
//             </div>
//           </DisclosurePanel>
//         </Disclosure>

//         <header className="bg-white shadow">
//           <div className="mx-auto max-w-7xl px-4 py-2 sm:px-6 lg:px-8">
//             <h1 className="text-xl font-bold tracking-tight text-gray-900">AlfaReverie</h1>
//           </div>
//         </header>
//       </div>
//     </>
//   )
// }

 
//  #######################################


// import React, { useContext } from 'react';
// import { Disclosure, Menu } from '@headlessui/react';
// import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
// import { Link, NavLink } from 'react-router-dom';
// import { UserContext } from '../../Contexts/UserContext'; // Import UserContext

// import loggedUser from '../../Assets/avatar-green.jpg';
// import loggedOutUser from '../../Assets/avatar-grey.jpg';

// const navigation = [
//   { name: 'Cartelle Cliniche', to: '/cartelle-cliniche', current: true },
//   { name: 'Pazienti', to: '/pazienti', current: false },
//   { name: 'Strumenti', to: '/strumenti', current: false },
//   { name: 'Calendario', to: '/calendario', current: false },
//   { name: 'Segnalazioni', to: '/segnalazioni', current: false },
// ];

// const userNavigation = [
//   { name: 'Profilo', to: '/profilo' },
//   { name: 'Impostazioni', to: '/impostazioni' },
//   { name: 'Disconnessione', to: '/logout' },
// ];

// const NouserNavigation = [
//   { name: 'Accedi', to: '/login' },
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

// export default function NavbarX() {
//   const { isLoggedIn, logout } = useContext(UserContext);

//   // Define the user object
//   const user = isLoggedIn
//     ? { name: 'Tom Cook', email: 'tom@example.com', imageUrl: loggedUser }
//     : { name: 'Guest', email: 'guest@example.com', imageUrl: loggedOutUser };

//   return (
//     <>
//       <div className="min-h-full sticky top-0 z-50">
//         <Disclosure as="nav" className="bg-gray-800">
//           {({ open }) => (
//             <>
//               <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//                 <div className="flex h-16 items-center justify-between">
//                   <div className="flex items-center">
//                     {isLoggedIn && (
//                       <div className="hidden md:block">
//                         <div className="ml-10 flex items-baseline space-x-4">
//                           {navigation.map((item) => (
//                             <NavLink
//                               key={item.name}
//                               to={item.to}
//                               className={({ isActive }) =>
//                                 classNames(
//                                   isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
//                                   'px-3 py-2 rounded-md text-sm font-medium'
//                                 )
//                               }
//                             >
//                               {item.name}
//                             </NavLink>
//                           ))}
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                   <div className="hidden md:block">
//                     <div className="ml-4 flex items-center md:ml-6">
//                       <button
//                         type="button"
//                         className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
//                       >
//                         <span className="sr-only">View notifications</span>
//                         <BellIcon className="h-6 w-6" aria-hidden="true" />
//                       </button>

//                       {/* Profile dropdown */}
//                       <Menu as="div" className="relative ml-3">
//                         <div>
//                           <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
//                             <span className="sr-only">Open user menu</span>
//                             <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
//                           </Menu.Button>
//                         </div>
//                         <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//                           {isLoggedIn ? (
//                             userNavigation.map((item) => (
//                               <Menu.Item key={item.name}>
//                                 {({ active }) => (
//                                   <NavLink
//                                     to={item.to}
//                                     className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
//                                     onClick={item.name === 'Disconnessione' ? logout : undefined}
//                                   >
//                                     {item.name}
//                                   </NavLink>
//                                 )}
//                               </Menu.Item>
//                             ))
//                           ) : (
//                             NouserNavigation.map((item) => (
//                               <Menu.Item key={item.name}>
//                                 {({ active }) => (
//                                   <NavLink
//                                     to={item.to}
//                                     className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
//                                   >
//                                     {item.name}
//                                   </NavLink>
//                                 )}
//                               </Menu.Item>
//                             ))
//                           )}
//                         </Menu.Items>
//                       </Menu>
//                     </div>
//                   </div>
//                   <div className="-mr-2 flex md:hidden">
//                     {/* Mobile menu button */}
//                     <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
//                       <span className="sr-only">Open main menu</span>
//                       {open ? (
//                         <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
//                       ) : (
//                         <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
//                       )}
//                     </Disclosure.Button>
//                   </div>
//                 </div>
//               </div>

//               <Disclosure.Panel className="md:hidden">
//                 {isLoggedIn && (
//                   <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
//                     {navigation.map((item) => (
//                       <Disclosure.Button
//                         key={item.name}
//                         as={NavLink}
//                         to={item.to}
//                         className={({ isActive }) =>
//                           classNames(
//                             isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
//                             'block px-3 py-2 rounded-md text-base font-medium'
//                           )
//                         }
//                       >
//                         {item.name}
//                       </Disclosure.Button>
//                     ))}
//                   </div>
//                 )}
//                 <div className="border-t border-gray-700 pt-4 pb-3">
//                   <div className="flex items-center px-5">
//                     <div className="flex-shrink-0">
//                       <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
//                     </div>
//                     <div className="ml-3">
//                       <div className="text-base font-medium leading-none text-white">{user.name}</div>
//                       <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
//                     </div>
//                     <button
//                       type="button"
//                       className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
//                     >
//                       <span className="sr-only">View notifications</span>
//                       <BellIcon className="h-6 w-6" aria-hidden="true" />
//                     </button>
//                   </div>
//                   <div className="mt-3 space-y-1 px-2">
//                     {isLoggedIn ? (
//                       userNavigation.map((item) => (
//                         <Disclosure.Button
//                           key={item.name}
//                           as={NavLink}
//                           to={item.to}
//                           className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
//                           onClick={item.name === 'Disconnessione' ? logout : undefined}
//                         >
//                           {item.name}
//                         </Disclosure.Button>
//                       ))
//                     ) : (
//                       NouserNavigation.map((item) => (
//                         <Disclosure.Button
//                           key={item.name}
//                           as={NavLink}
//                           to={item.to}
//                           className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
//                         >
//                           {item.name}
//                         </Disclosure.Button>
//                       ))
//                     )}
//                   </div>
//                 </div>
//               </Disclosure.Panel>
//             </>
//           )}
//         </Disclosure>
//         {/* Secondary bar with AlfaReverie */}
//         <div className="bg-gray-700">
//           <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8">
//             <div className="flex items-center justify-between">
//               <div className="flex items-center">
//                 {/* <img className="h-8 w-auto" src="/path/to/alfa-reverie-logo.png" alt="AlfaReverie" /> */}
//                 <span className="ml-3 text-white text-lg font-medium">AlfaReverie</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// // export default NavbarX;

// import React, { useContext } from 'react';
// import { Disclosure, Menu } from '@headlessui/react';
// import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
// import { Link, NavLink } from 'react-router-dom';
// import { UserContext } from '../../Contexts/UserContext'; // Import UserContext

// import loggedUser from '../../Assets/avatar-green.jpg';
// import loggedOutUser from '../../Assets/avatar-grey.jpg';

// const navigation = [
//   { name: 'Cartelle Cliniche', to: '/cartelle-cliniche', current: true },
//   { name: 'Pazienti', to: '/pazienti', current: false },
//   { name: 'Strumenti', to: '/strumenti', current: false },
//   { name: 'Calendario', to: '/calendario', current: false },
//   { name: 'Segnalazioni', to: '/segnalazioni', current: false },
//   { name: 'Utenti', to: '/users', current: true },
// ];

// const userNavigation = [
//   { name: 'Profilo', to: '/profilo' },
//   { name: 'Impostazioni', to: '/impostazioni' },
//   { name: 'Disconnetti', to: '/logout' },
// ];

// const NouserNavigation = [
//   { name: 'Accedi', to: '/login' },
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

// export default function NavbarX() {
//   const { isLoggedIn, user, logout } = useContext(UserContext);

//   return (
//     <>
//       <div className="min-h-full sticky top-0 z-50">
//         <Disclosure as="nav" className="bg-gray-800">
//           {({ open }) => (
//             <>
//               <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//                 <div className="flex h-16 items-center justify-between">
//                   <div className="flex items-center">
//                     <div className="flex-shrink-0">
//                       {/* <img
//                         className="h-8 w-8"
//                         src={isLoggedIn ? loggedUser : loggedOutUser}
//                         alt="User Avatar"
//                       /> */}
//                     </div>
//                     {isLoggedIn && (
//                       <div className="hidden md:block">
//                         <div className="ml-10 flex items-baseline space-x-4">
//                           {navigation.map((item) => (
//                             <NavLink
//                               key={item.name}
//                               to={item.to}
//                               className={({ isActive }) =>
//                                 classNames(
//                                   isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
//                                   'px-3 py-2 rounded-md text-sm font-medium'
//                                 )
//                               }
//                             >
//                               {item.name}
//                             </NavLink>
//                           ))}
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                   <div className="hidden md:block">
//                     <div className="ml-4 flex items-center md:ml-6">
//                       <button
//                         type="button"
//                         className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
//                       >
//                         <span className="sr-only">View notifications</span>
//                         <BellIcon className="h-6 w-6" aria-hidden="true" />
//                       </button>

//                       {/* Profile dropdown */}
//                       <Menu as="div" className="relative ml-3">
//                         <div>
//                           <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
//                             <span className="sr-only">Open user menu</span>
//                             <img className="h-8 w-8 rounded-full" src={isLoggedIn ? loggedUser : loggedOutUser} alt="" />
//                           </Menu.Button>
//                         </div>
//                         <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//                           {isLoggedIn ? (
//                             userNavigation.map((item) => (
//                               <Menu.Item key={item.name}>
//                                 {({ active }) => (
//                                   <NavLink
//                                     to={item.to}
//                                     className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
//                                     onClick={item.name === 'Disconnetti' ? logout : undefined}
//                                   >
//                                     {item.name}
//                                   </NavLink>
//                                 )}
//                               </Menu.Item>
//                             ))
//                           ) : (
//                             NouserNavigation.map((item) => (
//                               <Menu.Item key={item.name}>
//                                 {({ active }) => (
//                                   <NavLink
//                                     to={item.to}
//                                     className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
//                                   >
//                                     {item.name}
//                                   </NavLink>
//                                 )}
//                               </Menu.Item>
//                             ))
//                           )}
//                         </Menu.Items>
//                       </Menu>
//                     </div>
//                   </div>
//                   <div className="-mr-2 flex md:hidden">
//                     {/* Mobile menu button */}
//                     <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
//                       <span className="sr-only">Open main menu</span>
//                       {open ? (
//                         <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
//                       ) : (
//                         <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
//                       )}
//                     </Disclosure.Button>
//                   </div>
//                 </div>
//               </div>

//               <Disclosure.Panel className="md:hidden">
//                 {isLoggedIn && (
//                   <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
//                     {navigation.map((item) => (
//                       <Disclosure.Button
//                         key={item.name}
//                         as={NavLink}
//                         to={item.to}
//                         className={({ isActive }) =>
//                           classNames(
//                             isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
//                             'block px-3 py-2 rounded-md text-base font-medium'
//                           )
//                         }
//                       >
//                         {item.name}
//                       </Disclosure.Button>
//                     ))}
//                   </div>
//                 )}
//                 <div className="border-t border-gray-700 pt-4 pb-3">
//                   <div className="flex items-center px-5">
//                     <div className="flex-shrink-0">
//                       <img className="h-10 w-10 rounded-full" src={isLoggedIn ? loggedUser : loggedOutUser} alt="" />
//                     </div>
//                     <div className="ml-3">
//                       <div className="text-base font-medium leading-none text-white">{user.name}</div>
//                       <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
//                     </div>
//                     <button
//                       type="button"
//                       className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
//                     >
//                       <span className="sr-only">View notifications</span>
//                       <BellIcon className="h-6 w-6" aria-hidden="true" />
//                     </button>
//                   </div>
//                   <div className="mt-3 space-y-1 px-2">
//                     {isLoggedIn ? (
//                       userNavigation.map((item) => (
//                         <Disclosure.Button
//                           key={item.name}
//                           as={NavLink}
//                           to={item.to}
//                           className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
//                           onClick={item.name === 'Disconnetti' ? logout : undefined}
//                         >
//                           {item.name}
//                         </Disclosure.Button>
//                       ))
//                     ) : (
//                       NouserNavigation.map((item) => (
//                         <Disclosure.Button
//                           key={item.name}
//                           as={NavLink}
//                           to={item.to}
//                           className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
//                         >
//                           {item.name}
//                         </Disclosure.Button>
//                       ))
//                     )}
//                   </div>
//                 </div>
//               </Disclosure.Panel>
//             </>
//           )}
//         </Disclosure>
//         {/* Secondary bar with AlfaReverie */}
//         <div className="bg-gray-700">
//           <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8">
//             <div className="flex items-center justify-between">
//               <div className="flex items-center">
//                 <span className="ml-3 text-white text-lg font-medium">AlfaReverie</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// import React, { useContext } from 'react';
// import { Disclosure, Menu } from '@headlessui/react';
// import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
// import { Link, NavLink } from 'react-router-dom';
// import { UserContext } from '../../Contexts/UserContext'; // Import UserContext

// import loggedUser from '../../Assets/avatar-green.jpg';
// import loggedOutUser from '../../Assets/avatar-grey.jpg';

// const navigation = [
//   { name: 'Cartelle Cliniche', to: '/cartelle-cliniche', current: true },
//   { name: 'Pazienti', to: '/pazienti', current: false },
//   { name: 'Strumenti', to: '/strumenti', current: false },
//   { name: 'Calendario', to: '/calendario', current: false },
//   { name: 'Segnalazioni', to: '/segnalazioni', current: false },
//   { name: 'Utenti', to: '/users', current: true },
// ];

// const userNavigation = [
//   { name: 'Profilo', to: '/profilo' },
//   { name: 'Impostazioni', to: '/impostazioni' },
//   { name: 'Disconnetti', to: '/logout' },
// ];

// const NouserNavigation = [
//   { name: 'Accedi', to: '/login' },
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

// export default function NavbarX() {
//   const { isLoggedIn, user, logout } = useContext(UserContext);

//   // Define the user object
//   const userObject = isLoggedIn
//     ? { name: 'Tom Cook', email: 'tom@example.com', imageUrl: loggedUser }
//     : { name: 'Guest', email: 'guest@example.com', imageUrl: loggedOutUser };

//   return (
//     <>
//       <div className="min-h-full sticky top-0 z-50">
//         <Disclosure as="nav" className="bg-gray-800">
//           {({ open }) => (
//             <>
//               <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//                 <div className="flex h-16 items-center justify-between">
//                   <div className="flex items-center">
//                     {isLoggedIn && (
//                       <div className="hidden md:block">
//                         <div className="ml-10 flex items-baseline space-x-4">
//                           {navigation.map((item) => (
//                             <NavLink
//                               key={item.name}
//                               to={item.to}
//                               className={({ isActive }) =>
//                                 classNames(
//                                   isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
//                                   'px-3 py-2 rounded-md text-sm font-medium'
//                                 )
//                               }
//                             >
//                               {item.name}
//                             </NavLink>
//                           ))}
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                   <div className="hidden md:block">
//                     <div className="ml-4 flex items-center md:ml-6">
//                       <button
//                         type="button"
//                         className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
//                       >
//                         <span className="sr-only">View notifications</span>
//                         <BellIcon className="h-6 w-6" aria-hidden="true" />
//                       </button>

//                       {/* Profile dropdown */}
//                       <Menu as="div" className="relative ml-3">
//                         <div>
//                           <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
//                             <span className="sr-only">Open user menu</span>
//                             <img className="h-8 w-8 rounded-full" src={userObject.imageUrl} alt="" />
//                           </Menu.Button>
//                         </div>
//                         <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//                           {isLoggedIn ? (
//                             userNavigation.map((item) => (
//                               <Menu.Item key={item.name}>
//                                 {({ active }) => (
//                                   <NavLink
//                                     to={item.to}
//                                     className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
//                                     onClick={item.name === 'Disconnetti' ? logout : undefined}
//                                   >
//                                     {item.name}
//                                   </NavLink>
//                                 )}
//                               </Menu.Item>
//                             ))
//                           ) : (
//                             NouserNavigation.map((item) => (
//                               <Menu.Item key={item.name}>
//                                 {({ active }) => (
//                                   <NavLink
//                                     to={item.to}
//                                     className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
//                                   >
//                                     {item.name}
//                                   </NavLink>
//                                 )}
//                               </Menu.Item>
//                             ))
//                           )}
//                         </Menu.Items>
//                       </Menu>
//                     </div>
//                   </div>
//                   <div className="-mr-2 flex md:hidden">
//                     {/* Mobile menu button */}
//                     <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
//                       <span className="sr-only">Open main menu</span>
//                       {open ? (
//                         <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
//                       ) : (
//                         <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
//                       )}
//                     </Disclosure.Button>
//                   </div>
//                 </div>
//               </div>

//               <Disclosure.Panel className="md:hidden">
//                 {isLoggedIn && (
//                   <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
//                     {navigation.map((item) => (
//                       <Disclosure.Button
//                         key={item.name}
//                         as={NavLink}
//                         to={item.to}
//                         className={({ isActive }) =>
//                           classNames(
//                             isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
//                             'block px-3 py-2 rounded-md text-base font-medium'
//                           )
//                         }
//                       >
//                         {item.name}
//                       </Disclosure.Button>
//                     ))}
//                   </div>
//                 )}
//                 <div className="border-t border-gray-700 pt-4 pb-3">
//                   <div className="flex items-center px-5">
//                     <div className="flex-shrink-0">
//                       <img className="h-10 w-10 rounded-full" src={userObject.imageUrl} alt="" />
//                     </div>
//                     <div className="ml-3">
//                       <div className="text-base font-medium leading-none text-white">{userObject.name}</div>
//                       <div className="text-sm font-medium leading-none text-gray-400">{userObject.email}</div>
//                     </div>
//                     <button
//                       type="button"
//                       className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
//                     >
//                       <span className="sr-only">View notifications</span>
//                       <BellIcon className="h-6 w-6" aria-hidden="true" />
//                     </button>
//                   </div>
//                   <div className="mt-3 space-y-1 px-2">
//                     {isLoggedIn ? (
//                       userNavigation.map((item) => (
//                         <Disclosure.Button
//                           key={item.name}
//                           as={NavLink}
//                           to={item.to}
//                           className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
//                           onClick={item.name === 'Disconnetti' ? logout : undefined}
//                         >
//                           {item.name}
//                         </Disclosure.Button>
//                       ))
//                     ) : (
//                       NouserNavigation.map((item) => (
//                         <Disclosure.Button
//                           key={item.name}
//                           as={NavLink}
//                           to={item.to}
//                           className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
//                         >
//                           {item.name}
//                         </Disclosure.Button>
//                       ))
//                     )}
//                   </div>
//                 </div>
//               </Disclosure.Panel>
//             </>
//           )}
//         </Disclosure>
//         {/* Secondary bar with AlfaReverie */}
//         <div className="bg-gray-700">
//           <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8">
//             <div className="flex items-center justify-between">
//               <div className="flex items-center">
//                 {/* <img className="h-8 w-auto" src="/path/to/alfa-reverie-logo.png" alt="AlfaReverie" /> */}
//                 <span className="ml-3 text-white text-lg font-medium">AlfaReverie</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }


import React, { useContext } from 'react';
import { Disclosure, Menu } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, NavLink } from 'react-router-dom';
import { UserContext } from '../../Contexts/UserContext'; // Import UserContext

import loggedUser from '../../Assets/avatar-green.jpg';
import loggedOutUser from '../../Assets/avatar-grey.jpg';

const navigation = [
  { name: 'Cartelle Cliniche', to: '/cartelle-cliniche', current: true },
  { name: 'Pazienti', to: '/pazienti', current: false },
  { name: 'Strumenti', to: '/strumenti', current: false },
  { name: 'Calendario', to: '/calendario', current: false },
  { name: 'Segnalazioni', to: '/segnalazioni', current: false },
  { name: 'Utenti', to: '/users', current: true },
];

const userNavigation = [
  { name: 'Profilo', to: '/profilo' },
  { name: 'Impostazioni', to: '/impostazioni' },
  { name: 'Disconnetti', to: '/logout' },
];

const NouserNavigation = [
  { name: 'Accedi', to: '/login' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function NavbarX() {
  const { isLoggedIn, user, logout } = useContext(UserContext);

  // Use user information directly from UserContext
  const userObject = isLoggedIn
    ? { name: user.name, email: user.email, imageUrl: loggedUser }
    : { name: 'Guest', email: 'guest@example.com', imageUrl: loggedOutUser };

  return (
    <>
      <div className="min-h-full sticky top-0 z-50">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    {isLoggedIn && (
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                          {navigation.map((item) => (
                            <NavLink
                              key={item.name}
                              to={item.to}
                              className={({ isActive }) =>
                                classNames(
                                  isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                  'px-3 py-2 rounded-md text-sm font-medium'
                                )
                              }
                            >
                              {item.name}
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <button
                        type="button"
                        className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src={userObject.imageUrl} alt="" />
                          </Menu.Button>
                        </div>
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {isLoggedIn ? (
                            userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <NavLink
                                    to={item.to}
                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                    onClick={item.name === 'Disconnetti' ? logout : undefined}
                                  >
                                    {item.name}
                                  </NavLink>
                                )}
                              </Menu.Item>
                            ))
                          ) : (
                            NouserNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <NavLink
                                    to={item.to}
                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                  >
                                    {item.name}
                                  </NavLink>
                                )}
                              </Menu.Item>
                            ))
                          )}
                        </Menu.Items>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                {isLoggedIn && (
                  <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as={NavLink}
                        to={item.to}
                        className={({ isActive }) =>
                          classNames(
                            isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'block px-3 py-2 rounded-md text-base font-medium'
                          )
                        }
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                )}
                <div className="border-t border-gray-700 pt-4 pb-3">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={userObject.imageUrl} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">{userObject.name}</div>
                      <div className="text-sm font-medium leading-none text-gray-400">{userObject.email}</div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {isLoggedIn ? (
                      userNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as={NavLink}
                          to={item.to}
                          className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                          onClick={item.name === 'Disconnetti' ? logout : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))
                    ) : (
                      NouserNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as={NavLink}
                          to={item.to}
                          className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))
                    )}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {/* Secondary bar with AlfaReverie */}
        <div className="bg-gray-700">
          <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                {/* <img className="h-8 w-auto" src="/path/to/alfa-reverie-logo.png" alt="AlfaReverie" /> */}
                <span className="ml-3 text-white text-lg font-medium">AlfaReverie</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
