// import React, { useEffect, useState } from 'react';
// import Template from './Template'; // Import the Template component
// import Cookies from 'js-cookie';
// import './Login.css';

// const Login = () => {
//   const [csrfToken, setCsrfToken] = useState(null);
//   const [googleToken, setGoogleToken] = useState(null);
//   const [nonce, setNonce] = useState('');
//   const [csrfResponse, setCsrfResponse] = useState('');

//   const fetchCsrfToken = () => {
//     const csrfUrl = '/api/getcsrf/';
//     const fullUrl = `${window.location.origin}${csrfUrl}`;
//     console.log('Fetching CSRF token from:', fullUrl);

//     // Fetch the CSRF token from the backend
//     fetch(fullUrl, {
//       method: 'GET',
//       credentials: 'include', // Include credentials to allow setting cookies
//       redirect: 'follow', // Follow redirects
//     })
//       .then((response) => {
//         if (response.redirected) {
//           console.error('Request was redirected to:', response.url);
//           throw new Error('Request was redirected');
//         }
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         return response.text();
//       })
//       .then((text) => {
//         try {
//           const data = JSON.parse(text);
//           const csrfToken = Cookies.get('csrftoken');
//           setCsrfToken(csrfToken);
//           setCsrfResponse(`CSRF token set: ${csrfToken}`);
//           console.log('CSRF token set:', csrfToken);

//           // Set the CSRF token cookie with SameSite=None and Secure=true
//           Cookies.set('csrftoken', csrfToken, {
//             sameSite: 'None',
//             secure: true,
//           });
//         } catch (error) {
//           console.error('Error setting CSRF token:', error);
//           console.error('Full Response:', text);
//           setCsrfResponse(`Error setting CSRF token: ${error.message}`);
//         }
//       })
//       .catch((error) => {
//         console.error('Error setting CSRF token:', error);
//         setCsrfResponse(`Error setting CSRF token: ${error.message}`);
//       });
//   };

//   useEffect(() => {
//     // Get the nonce from the meta tag
//     const nonceMetaTag = document.querySelector('meta[name="csp-nonce"]');
//     if (nonceMetaTag) {
//       const nonceValue = nonceMetaTag.getAttribute('content');
//       setNonce(nonceValue);
//       console.log('Nonce set:', nonceValue);
//     } else {
//       console.error('Nonce meta tag not found');
//     }
//   }, []);

//   useEffect(() => {
//     if (nonce) {
//       // Load the Google Identity Services script with the nonce
//       const script = document.createElement('script');
//       script.src = 'https://accounts.google.com/gsi/client';
//       script.nonce = nonce;
//       script.async = true;
//       script.defer = true;
//       script.onload = () => {
//         window.google.accounts.id.initialize({
//           client_id: '670086124055-8k9idh6udml9j3orluelpugr89hq40ob.apps.googleusercontent.com', // Replace with your Google client ID
//           callback: handleGoogleLogin,
//         });
//         window.google.accounts.id.renderButton(
//           document.getElementById('google-login-button'),
//           { theme: 'outline', size: 'large' } // Customize the button as needed
//         );
//       };
//       document.body.appendChild(script);
//     }
//   }, [nonce]);

//   const handleGoogleLogin = (response) => {
//     console.log('Google login response:', response);
//     setGoogleToken(response.credential);
//     // You can now send the Google token to your backend for further processing

//     fetch('/auth/google/callback', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'X-CSRFToken': Cookies.get('csrftoken'),
//       },
//       credentials: 'include',
//       body: JSON.stringify({ token: response.credential }),
//     })
//       .then((res) => res.json())
//       .then((data) => {
//         console.log('Backend response:', data);
//         // Handle the backend response as needed
//       })
//       .catch((error) => {
//         console.error('Error sending Google token to backend:', error);
//       });
//   };

//   return (
//     <Template>
//       {/* Your login form and other components */}
//       {/* <button onClick={fetchCsrfToken}>Fetch CSRF Token</button>
//       <div id="csrf-response">{csrfResponse}</div> */}
//       <div id="google-login-button"></div>
//     </Template>
//   );
// };

// export default Login;
import React, { useEffect, useState, useContext } from 'react';
import Template from './Template'; // Import the Template component
import Cookies from 'js-cookie';
import './Login.css';
import { UserContext } from '../../Contexts/UserContext';

const Login = () => {
  const [nonce, setNonce] = useState('');
  const { login } = useContext(UserContext);

  useEffect(() => {
    // Get the nonce from the meta tag
    const nonceMetaTag = document.querySelector('meta[name="csp-nonce"]');
    if (nonceMetaTag) {
      const nonceValue = nonceMetaTag.getAttribute('content');
      setNonce(nonceValue);
      console.log('Nonce set:', nonceValue);
    } else {
      console.error('Nonce meta tag not found');
    }
  }, []);

  useEffect(() => {
    if (nonce) {
      // Load the Google Identity Services script with the nonce
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.nonce = nonce;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: '670086124055-8k9idh6udml9j3orluelpugr89hq40ob.apps.googleusercontent.com', // Replace with your Google client ID
          callback: handleGoogleLogin,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('google-login-button'),
          { theme: 'outline', size: 'large' } // Customize the button as needed
        );
      };
      document.body.appendChild(script);
    }
  }, [nonce]);

  const handleGoogleLogin = (response) => {
    console.log('Google login response:', response);
  
    // Send the Google token to the backend
    fetch('/auth/google/callback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      credentials: 'include',
      body: JSON.stringify({ token: response.credential }),
    })
      .then((res) => res.json().then((data) => ({ status: res.status, body: data })))
      .then(({ status, body }) => {
        console.log('Backend response:', body);
        // Handle the backend response as needed
        if (status === 200 && body.status === 'success') {
          const userInfo = {
            name: body.name,
            email: body.email,
          };
          login(userInfo);
        } else {
          handleError(status, body.message);
        }
      })
      .catch((error) => {
        console.error('Error sending Google token to backend:', error);
        handleError(500, 'Internal server error');
      });
  };
  
  const handleError = (status, message) => {
    switch (status) {
      case 400:
        alert(`Bad Request: ${message}`);
        break;
      case 401:
        alert(`Unauthorized: ${message}`);
        break;
      case 403:
        alert(`Forbidden: ${message}`);
        break;
      case 500:
      default:
        alert(`Internal Server Error: ${message}`);
        break;
    }
  };

  return (
    <Template>
      <div id="google-login-button"></div>
    </Template>
  );
};

export default Login;